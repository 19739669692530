<template>
    <!-- isMobileInitialized to avoid blinking -->
    <div>
        <ClientSidebar v-if="!isMobile" />

        <ClientHeader v-if="!isMobile" />
        <ClientHeaderMobile v-if="isMobile" />

        <!-- https://nuxt.com/docs/guide/directory-structure/layouts#default-layout -->
        <!-- In a layout file, the content of the page will be displayed in the <slot /> component. -->
        <!-- ERROR WITH CHANGING LAYOUTS 
             https://vuejs.org/guide/built-ins/suspense.html
             https://github.com/nuxt/nuxt/issues/13309#issuecomment-1935405858
        -->
        <slot />
    </div>
</template>

<script setup lang="ts">

const { isMobile, isMobileInitialized } = useIsMobile();

</script>