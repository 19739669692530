<template>
  <div class="sidebar-container">
    <div class="inner">
      <div class="logo">
        <!-- https://nuxt.com/modules/icons -->
        <Icon name="LogoWhite" />
      </div>
    </div>
    <div class="inner">
      <div class="menu-items">
        <ul>
          <!-- 
                        https://nuxt.com/modules/icon
                        https://icones.js.org/
                     -->
          <li v-if="role === 'admin'">
            <NuxtLink to="/admin">
              <Icon name="dashicons:redo"></Icon>
              <span>Admin</span>
            </NuxtLink>
          </li>

          <li>
            <NuxtLink :to="dashboardLink">
              <!-- https://icones.js.org/collection/dashicons -->
              <Icon name="dashicons:cart"></Icon>
              <span>Sales</span>
            </NuxtLink>
          </li>

          <li>
            <NuxtLink
              :to="eventRequestLink"
              :class="[
                getRoute == 'client-event'
                  ? 'router-link-active router-link-exact-active'
                  : '',
              ]"
            >
              <Icon name="dashicons:calendar-alt"></Icon>
              <span>Event request</span>
            </NuxtLink>
          </li>

          <li v-if="role === 'client'">
            <NuxtLink :to="payoutsLink">
              <!-- https://icones.js.org/collection/dashicons -->
              <Icon name="dashicons:money-alt"></Icon>
              <span>Payouts</span>
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>

    <div class="inner">
      <div class="menu-items sticky-bottom">
        <ul>
          <li>
            <NuxtLink to="/" target="_blank" rel="noopener noreferrer">
              <Icon name="dashicons:admin-site"></Icon>
              <span>Live site</span>
            </NuxtLink>
          </li>
          <li v-if="authenticated">
            <a href="/login" @click="logoutAction">
              <Icon name="dashicons:unlock"></Icon>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia' // import storeToRefs helper hook from pinia
import { useAuthStore } from '~/store/auth' // import the auth store we just created

const { authenticated, role } = storeToRefs(useAuthStore()) // make authenticated state reactive with storeToRefs

const { logOutUser } = useAuthStore() // use loginUser action from  auth store
const router = useRouter()
const route = useRoute()

// Must be selected from route
const payoutsLink = ref('')
const dashboardLink = ref('')
const eventRequestLink = ref('')

const { currentRoute } = useRouter()
// getRoute used for setting the active class on the sidebar
const getRoute = ref(currentRoute.value.name)

const updateLinks = () => {
  if (currentRoute.value.params.id) {
    getRoute.value = currentRoute.value.name
    payoutsLink.value = `/client/${currentRoute.value.params.id}/payouts`
    dashboardLink.value = `/client/${currentRoute.value.params.id}`
    eventRequestLink.value = `/client/${currentRoute.value.params.id}/event`
  }
}

// Watch router
// When we click on a client profile, we want to update the links in the sidebar
watch(
  () => currentRoute.value,
  async (value) => {
    updateLinks()
  },
  { deep: true, immediate: true }
)

// Ensure links are updated on component mount
onMounted(() => {
  updateLinks()
})

const logoutAction = () => {
  logOutUser()
  router.push('/login')
}
</script>

<style scoped>
.sidebar-container {
  background-color: #111827;
  text-align: left;
  position: fixed;
  left: 0;
  width: 20%;
  height: 100vh;
  z-index: 11;
  overflow-y: scroll;
}

.sidebar-container .inner {
  padding: 2em;
}

.logo {
  padding: 0 0.8em;
}

.logo .icon {
  line-height: 1;
  font-size: 4rem;
  display: block;
  color: #ffffff;
  margin-bottom: 24px;
}

.logo .icon svg {
  vertical-align: top;
}

.menu-items ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-items li {
  margin-bottom: 0.8em;
}

.menu-items li:hover {
  background-color: #1f2937;
  border-radius: 10px;
}

.menu-items li:hover a,
.menu-items li:hover .icon {
  color: #ffffff;
}

.menu-items li a.router-link-exact-active,
.menu-items li a.router-link-exact-active .icon {
  color: #ffffff;
}

.menu-items li a.router-link-exact-active,
.menu-items li a.router-link-exact-active .icon {
  color: #ffffff;
}

.menu-items li:has(> a.router-link-exact-active) {
  background-color: #1f2937;
  border-radius: 10px;
}

.menu-items li .icon {
  margin-right: 0.5em;
  font-size: 1.6rem;
  color: #9ba3af;
  line-height: 1;
}

.menu-items li .icon svg {
  color: #9ba3af;
  stroke: none;
  fill: #ffffff;
}

.menu-items a {
  color: #9ba3af;
  font-family: var(--font-regular);
  font-size: 1rem;
  display: block;
  padding: 0.4em;
  line-height: 1;
  display: grid;
  grid-template-columns: 1fr 12fr;
  align-items: center;
  justify-items: left;
  transition: none;
}

.shadow {
  text-shadow: 1px 1px 1px #3d4651;
}
</style>
